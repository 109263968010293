window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
      gtag('event', 'conversion', {
      'send_to': 'AW-16721069823/j0tGCPHdkNgZEP-NnaU-',
      'value': 1.0,
      'currency': 'GBP'
  });
});